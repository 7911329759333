import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../../components/Global/MetaHead"
import Header from "../../components/Layout/header"
import Footer from "../../components/Layout/footer"

const ThankYouPage = ({ data }) => (
  <>
    <SEO
      title="Дякуємо за відгук - Центр Державних Ліцензій України"
      description="Ваше звернення побачить керівник і якщо ви залишили контакти, то
        звяжеться з Вами!"
      image={`https:${data.ogImage.fixed.src}`}
      url="/feedback/done/"
      lang="uk"
      noindex="noindex"
    />
    <Header />
    <section className="dyakuemo container py-5">
      <h1 className="my-5">Дякуємо за допомогу!</h1>
      <p className="text-center">
        Ваше звернення побачить керівник і якщо ви залишили контакти, то
        звяжеться з Вами!
      </p>

      <div className="pt-3 pb-2 title2">Що робити далі?</div>
      <ol>
        <li>
          Можете перейти на{" "}
          <Link to="/" replace>
            головну сторінку
          </Link>{" "}
          і продовжити роботу із сайтом.
        </li>
        <li>Або закрити це вікно.</li>
      </ol>
    </section>
    <Footer />
  </>
)
export const query = graphql`
  {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
  }
`

export default ThankYouPage
